.bottom-right-container {
  bottom: 0;
  position: fixed;
  left: 0;

  width: 100%;
  border-top: 1px solid #ced4da;
}

.login-center-container {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 100%;

  flex-direction: column;
}

.footer-content {
  height: 35px;
  width: 100%;
  padding-top: 5px;
}

@media (max-width: 576px) {
  .footer-content {
    height: 50px;
  }
}

@media (min-width: 576px) {
  .login-center-container {
    justify-content: center;
    padding-bottom: 110px;
  }
}
